import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { MDBNavbar, MDBNavbarNav, MDBNavItem, MDBCollapse, MDBDropdown, MDBDropdownItem,
  MDBHamburgerToggler, MDBNavbarBrand,  MDBContainer} from 'mdbreact';
import CookieConsent from "react-cookie-consent";
import FadeIn from 'react-fade-in';
import NestedOverlay from '../layout/ShowOverlay';


export class MainContent extends Component {
  constructor() {
    super();
    this.state = { tabIndex: 0 };
  }

  state = {
    collapse1: false,
    collapseID: ''
  }
  
  toggleMainCollapse = collapseId => {
    this.setState({
      ...this.state,
      [collapseId]: !this.state[collapseId]
    });
  }

  selectTabCollapse = selectedTab => {
    this.setState({
      ...this.state,
      ['collapse1']: !this.state['collapse1']
    });
    this.setState({ tabIndex: selectedTab });
  }

  render() { 
     return (
      
       <div className="site-main"> 
        <div className='mobilemenu'>
        <MDBContainer className='fw-container'>
        <MDBNavbar>
          <MDBContainer fluid>
            <MDBNavbarBrand><div className='ttm-textcolor-background'>Doub</div></MDBNavbarBrand>
            <MDBNavbarBrand><h1 className='ttm-textcolor-white mt-30'>DoubleStar&reg;</h1></MDBNavbarBrand>
            <MDBHamburgerToggler id="hamburger1" onClick={()=> this.toggleMainCollapse('collapse1')} />
              <MDBCollapse isOpen={this.state.collapse1} navbar>
                <MDBNavbarNav right>
                  <MDBNavItem active>
                    <MDBDropdown>
                      <MDBDropdownItem onClick={()=> this.selectTabCollapse(0)}><img className='s-show' src="images/tab-images/tab1-lm.png" /><img className='m-show' src="images/tab-images/tab1-mm.png" />Toujeo&reg; DoubleStar&reg;</MDBDropdownItem>
                      <MDBDropdownItem onClick={()=> this.selectTabCollapse(1)}><img className='s-show' src="images/tab-images/tab2-lm.png" /><img className='m-show' src="images/tab-images/tab2-mm.png" />Anvend Toujeo&reg; DoubleStar&reg; rigtigt</MDBDropdownItem>
                      <MDBDropdownItem onClick={()=> this.selectTabCollapse(2)}><img className='s-show' src="images/tab-images/tab3-lm.png" /><img className='m-show' src="images/tab-images/tab3-mm.png" />Patientveijledningen</MDBDropdownItem>
                      <MDBDropdownItem onClick={()=> this.selectTabCollapse(3)}><img className='s-show' src="images/tab-images/tab4-lm.png" /><img className='m-show' src="images/tab-images/tab4-mm.png" />Sund kost ved type 2-diabetes</MDBDropdownItem>
                      <MDBDropdownItem onClick={()=> this.selectTabCollapse(4)}><img className='s-show' src="images/tab-images/tab5-lm.png" /><img className='m-show' src="images/tab-images/tab5-mm.png" />Hverdagen med type 2-diabetes</MDBDropdownItem>
                      <MDBDropdownItem onClick={()=> this.selectTabCollapse(5)}><img className='s-show' src="images/tab-images/tab6-lm.png" /><img className='m-show' src="images/tab-images/tab6-mm.png" />Rejs trygt</MDBDropdownItem>
                    </MDBDropdown>
                  </MDBNavItem>
                </MDBNavbarNav>
              </MDBCollapse>
            </MDBContainer>
          </MDBNavbar>
        </MDBContainer>
        

        </div>


        <section>
              <div className='container-xl mt-20'>
                <div className='row'>
                  <div className="col-3 above-menu">
                    <img className="img-fluid img-fixed-min" src="/images/logo.png"/>
                  </div>
                </div>
              </div>
            </section>
            
        <div>
        <NestedOverlay /></div>

        <div>

      </div>
         {/* tab section */}
         {/*<section className="ttm-row tab-section clearfix">*/}
           <div className="container-xl mt-30">
             {/* row end */}
             {/* row */}
             <div className="row">
               <div className="col-lg-12">
                 <div className="ttm-tabs text-center ttm-tab-style-classic style1">
                 <Tabs selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.setState({ tabIndex })}>{/* tabs */}
                      <TabList className="tabs">
                        <Tab className="tab" title="Hi-Tech"><span><img id="logo-img" className="img-fluid img-fluid-restrict" src="images/tab-images/tab1.png" /><br/><strong className='d-none d-md-block'>Toujeo&reg; DoubleStar&reg;</strong></span></Tab>
                        <Tab className="tab" title="Media"><span><img className="img-fluid img-fluid-restrict" src="images/tab-images/tab2.png" /><br/><strong className='d-none d-md-block'>Anvend Toujeo&reg; DoubleStar&reg; rigtigt</strong></span></Tab>
                        <Tab className="tab" title="Industrial"><span><img className="img-fluid img-fluid-restrict" src="images/tab-images/tab3.png" /><br/><strong className='d-none d-md-block'>Patientveijledning</strong></span></Tab>
                        <Tab className="tab" title="Banking"><span><img className="img-fluid img-fluid-restrict" src="images/tab-images/tab4.png" /><br/><strong className='d-none d-md-block'>Sund kost ved type 2-diabetes</strong></span></Tab>
                        <Tab className="tab" title="Medical"><span><img className="img-fluid img-fluid-restrict" src="images/tab-images/tab5.png" /><br/><strong className='d-none d-md-block'>Hverdagen med type 2-diabetes</strong></span></Tab>
                        <Tab className="tab" title="Rejs"><span><img className="img-fluid img-fluid-restrict" src="images/tab-images/tab6.png" /><br/><strong className='d-none d-md-block'>Rejs trygt</strong></span></Tab>
                      </TabList>
                      <div className="box-shadow content-tab">
                        <FadeIn>
                        <TabPanel>
                          <div className="content-inner active">
                              <div className="row m-0 spacing-12">
                                  <div className="col-lg-12">
                                      <div className="text-center">
                                          <h1 className="title mb-35 mt-35">Toujeo&reg; DoubleStar&reg;</h1>
                                          <h3 className="mb-35 mt-35">Vejledning til patienter<br/>
                                              med type 2-diabetes</h3>
                                          <div className="ttm_single_image-wrapper mb-35 mt-35">
                                              <img className="img-fluid" src="images/content-images/ip_double.png" title="Toujeo SoloStar" alt="Toujeo SoloStar" />
                                          </div>
                                          <h4 className="mb-35 mt-35">Velkommen til Toujeo&reg; vejledning som indeholder<br/>
                                              information til dig, som har fået Toujeo&reg; DoubleStar&reg;, så din<br/>
                                              behandling bliver så optimal som mulig.</h4>
                                      </div>
                                  </div>
                              </div>
                              <div className="row m-0 ttm-bgcolor-white mobile-paging">

                            <div className="col-12 text-right">
                            <span className='ttm-textcolor-background'>Næste Side</span><a onClick={()=> this.setState({ tabIndex: 1 })} className="ttm-btn ttm-icon ttm-icon_element-fill ttm-icon_element-color-background ttm-icon_element-size-lg">
                             <i className="fa fa-angle-right" />
                              </a>
                            </div>
                          </div>{/* row end */}
                          </div>
                        </TabPanel>
                        <TabPanel>
                        <div className="content-inner active">
                          <div className="row m-0 spacing-12">
                            <div className='col-lg-12 d-sm-block d-md-none text-mobile-center mb-20'>
                              <span><h1>Anvend Toujeo&reg; DoubleStar&reg; rigtigt</h1><img className="img-fluid img-fluid-restrict" src="images/tab-images/tab2-1.png" /></span></div>
                            <div className="col-lg-6">
                              <div className="text-left text-mobile-center">
                                <h2>Lær din Toujeo&reg; DoubleStar&reg; pen at kende</h2>
                                <p>Toujeo&reg; DoubleStar&reg; pen hjælper dig med at injicere den rigtige mængde insulin.</p>
                                <p><strong>Se videoen og lær</strong>, hvordan pennen benyttes, hvordan man opbevarer den, og hvordan du indstiller mængden af insulin.</p>
                                
                              </div>
                            </div>
                            
                          {/* <iframe width={560} height={315} src="https://www.youtube.com/embed/f50XZ-DIqAo" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen /> */}

                            <div className="col-lg-4">
                                {/* ttm_single_image-wrapper */}
                                <div className="ttm_single_image-wrapper">
                                  <img className="img-fluid" src="images/content-images/video1_double.png" title="Toujeo DoubleStar" alt="Toujeo DoubleStar" />
                                </div>{/* ttm_single_image-wrapper end */}
                            </div>
                          </div>{/* row end */}
                          <div className="row m-0 spacing-12 ttm-bgcolor-grey">
                            <div className="col-lg-6">
                              <div className="text-left text-mobile-center">
                                <h4>Find flere informationer i Patientvejledningen om Toujeo&reg; DoubleStar&reg;.</h4>
                                <p>Hvis du er usikker på, hvordan du bruger Toujeo&reg; DoubleStar&reg; kan du altid bede din praktiserende Læge eller sygeplejerske om hjælp.</p>
                              </div>
                            </div>
                            <div className="col-lg-4">
                                {/* ttm_single_image-wrapper */}
                                <div className="ttm_single_image-wrapper">
                                  <img className="img-fluid" src="images/content-images/link1.png" title="Toujeo DoubleStar" alt="Toujeo DoubleStar" />
                                </div>{/* ttm_single_image-wrapper end */}
                            </div>
                            <div className='col-lg-2 d-flex align-items-center text-mobile-center'>
                              <div className="ttm-btn-box pr-20 pb-20">
                                <a className="ttm-btn ttm-btn-size-md rounded-circle ttm-btn-style-border ttm-textcolor-white ttm-bgcolor-background">HENT PATIENTVEIJLEDNING</a>
                            </div></div>
                          </div>{/* row end */}
                          <div className="row m-0 spacing-12 ttm-bgcolor-darkgrey">
                            <div className="col-lg-12">
                              <div className="text-center ttm-textcolor-white">
                              
                              <p>Hvis du har spørgsmål i forbindelse med din diabetesbehandling, skal du ikke tøve med at kontact din sygeplejerske eller læge.</p>
                              </div>
                            </div>
                          </div>{/* row end */}
                          
                          <div className="row m-0 ttm-bgcolor-white mobile-paging">
                            <div className="col-6 text-left">
                                
                              <a onClick={()=> this.setState({ tabIndex: 0 })} className="ttm-btn ttm-icon ttm-icon_element-fill ttm-icon_element-color-background ttm-icon_element-size-lg">
                                  <i className="fa fa-angle-left" />
                              </a>
                            </div>
                            <div className="col-6 text-right">
                            <a onClick={()=> this.setState({ tabIndex: 2 })} className="ttm-btn ttm-icon ttm-icon_element-fill ttm-icon_element-color-background ttm-icon_element-size-lg">
                                  <i className="fa fa-angle-right" />
                              </a>
                            </div>
                          </div>{/* row end */}

                        </div>
                        </TabPanel>

                        <TabPanel>
                        <div className="content-inner active">
                            <div className="row m-0 spacing-12">
                                <div className="col-lg-6">
                                  <div className="text-left text-mobile-center">
                                    <h2>Patientveijledningen</h2>
                                    <div className='col-lg-12 d-sm-block d-md-none text-mobile-center mb-20'>
                                        <span><img className="img-fluid img-fluid-restrict" src="images/tab-images/tab3.png" /></span></div>
                                    <p>Denne brochure er skrevet til dig, som har diabetes, og som har fået ordineret Toujeo&reg; DoubleStar&reg; af din læge.</p>
                                    <p><strong>Det vigtigste mål med enhver diabetesbehandling er, at du er tryg med din diabetes, får et så normalt blodsukkerniveau som muligt og forebygger følgesygdomme nu og i fremtiden.</strong></p>
                                    <p>Patientveijledningen giver dig tips og informationer, som kan støtte dig i at forstå din diabetes og at udvikle gode daglige rutiner.</p>
                                    <p>Du finder også information om insulinbehandling og praktiske informationer om brugen af din Toujeo&reg; DoubleStar&reg; pen</p>
                                  </div>
                                </div>
                                <div className="col-lg-4">
                                    {/* ttm_single_image-wrapper */}
                                    <div className="ttm_single_image-wrapper">
                                      <img className="img-fluid" src="images/content-images/link2.png" title="Toujeo SoloStar" alt="Toujeo SoloStar" />
                                    </div>{/* ttm_single_image-wrapper end */}
                                </div>
                                <div className='col-lg-2 d-flex align-items-center'>
                <div className="ttm-btn-box pr-20 pb-20">
                  <a className="ttm-btn ttm-btn-size-md rounded-circle ttm-btn-style-border ttm-textcolor-white ttm-bgcolor-background">HENT PATIENTVEIJLEDNING</a>
              </div></div>
                              </div>{/* row end */}
                              <div className="row m-0 spacing-12 ttm-bgcolor-darkgrey">
                                <div className="col-lg-12">
                                  <div className="text-center">
                                  <p>Læs mere om virkning og bivirkninger i indlægssedlen. Hvis du oplever bivirkninger, skal du tale med din læge, apotek eller sygeplejerske. Dette gælder også for eventuelle bivirkninger, 
                                  der ikke er nævnt i indlægssedlen. Du kan også rapportere bivirkninger direkte til Lægemiddelstyrelsen, <a className='ttm-textcolor-white' href="http://www.meldenbivirkning.dk/">www.meldenbivirkning.dk.</a> 
                                  &nbsp;Ved at rapportere bivirkninger kan du hjælpe med at øge informationen om Lægemiddelsikkerhed.</p>
                                    
                                  </div>
                                </div>
                              </div>{/* row end */}
                              <div className="row m-0 ttm-bgcolor-white mobile-paging">
                            <div className="col-6 text-left">
                                
                              <a onClick={()=> this.setState({ tabIndex: 1 })} className="ttm-btn ttm-icon ttm-icon_element-fill ttm-icon_element-color-background ttm-icon_element-size-lg">
                                  <i className="fa fa-angle-left" />
                              </a>
                            </div>
                            <div className="col-6 text-right">
                            <a onClick={()=> this.setState({ tabIndex: 3 })} className="ttm-btn ttm-icon ttm-icon_element-fill ttm-icon_element-color-background ttm-icon_element-size-lg">
                                  <i className="fa fa-angle-right" />
                              </a>
                            </div>
            </div>{/* row end */}
                            </div>
                        </TabPanel>

                        <TabPanel>
                        <div className="content-inner active">
                              <div className="row m-0 spacing-12">
                              <div className='col-lg-12 d-sm-block d-md-none text-mobile-center mb-20'>
                              <span><h1>Sund kost ved type 2-diabetes</h1><img className="img-fluid img-fluid-restrict" src="images/tab-images/tab4.png" /></span></div>
                                <div className="col-lg-6">
                                  <div className="text-left text-mobile-center">
                                    <h2>Inspiration til sund kost ved type 2-diabetes</h2>
                                    <p>Sund kost er grundlæggende vigtig for din sundhed. Når du har diabetes, hjælper det at have kendskab til hvordan kostan, sammen med andre faktorer, kan påvirke dit blodsukker, så du kan leve dit liv så sundt som muligt.</p>
                                    <p>Hvis dus har fået kost-anbefalinger fra din læge eller sygeplejerske, er det vigtig at du følger disse. <strong>Du kan desuden hente inspiration ved at se videoen "Diabetes og kost".</strong></p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                <div className="ttm_single_image-wrapper">
                                      <img className="img-fluid" src="images/content-images/video2.png" title="Toujeo SoloStar" alt="Toujeo SoloStar" />
                                    </div>{/* ttm_single_image-wrapper end */}
                                </div>
                              </div>{/* row end */}
                              <div className="row m-0 spacing-12 ttm-bgcolor-grey">
                                <div className="col-lg-6">
                                  <div className="text-left text-mobile-center">
                                    <h2>Diabetes og kost</h2>
                                    <p>På diabetesforeningers hjemmeside finder du diabetesvenlige kostråd og et stort udvalg af opskrifter, som kan give dig inspiration til en sundere hverdag med diabetes.</p>
                                  </div>
                                </div>
                                <div className="col-lg-4 d-flex align-items-center">
                                    <div className="ttm_single_image-wrapper">
                                      <img className="img-fluid" src="images/content-images/diabetes.png" title="Toujeo SoloStar" alt="Toujeo SoloStar" />
                                    </div>{/* ttm_single_image-wrapper end */}
                                </div>
                                <div className='col-lg-2 d-flex align-items-center'>
                                  <div className="ttm-btn-box pr-20 pb-20">
                                    <a className="ttm-btn ttm-btn-size-md rounded-circle ttm-btn-style-border ttm-textcolor-white ttm-bgcolor-background">BESØG HJEMMESIDEN</a>
                                </div></div>
                              </div>{/* row end */}
                              <div className="row m-0 spacing-12 ttm-bgcolor-darkgrey">
                                <div className="col-lg-12">
                                  <div className="text-center ttm-textcolor-white">
                                  <p>Læs mere om virkning og bivirkninger i indlægssedlen. Hvis du oplever bivirkninger, skal du tale med din læge, apotek eller sygeplejerske. Dette gælder også for eventuelle bivirkninger, 
                                      der ikke er nævnt i indlægssedlen. Du kan rapportere bivirkninger direkte til Lægemiddelstyrelsen, <a href="http://www.meldenbivirkning.dk/">www.meldenbivirkning.dk.</a> 
                                      &nbsp;Ved at rapportere bivirkninger kan du hjælpe med at øge informationen om Lægemiddelsikkerhed.</p>
                                  </div>
                                </div>
                              </div>{/* row end */}
                              <div className="row m-0 ttm-bgcolor-white mobile-paging">
                              <div className="col-6 text-left">
                                
                                <a onClick={()=> this.setState({ tabIndex: 2 })} className="ttm-btn ttm-icon ttm-icon_element-fill ttm-icon_element-color-background ttm-icon_element-size-lg">
                                    <i className="fa fa-angle-left" />
                                </a>
                              </div>
                              <div className="col-6 text-right">
                              <a onClick={()=> this.setState({ tabIndex: 4 })} className="ttm-btn ttm-icon ttm-icon_element-fill ttm-icon_element-color-background ttm-icon_element-size-lg">
                                    <i className="fa fa-angle-right" />
                                </a>
                              </div>
            </div>{/* row end */}
                            </div>
                        </TabPanel>

                        <TabPanel>

                        <div className="content-inner active">
            <div className="row m-0 spacing-12">
                              <div className='col-lg-12 d-sm-block d-md-none text-mobile-center mb-20'>
                              <span><h1>Hverdagen med type 2-diabetes</h1><img className="img-fluid img-fluid-restrict" src="images/tab-images/tab5.png" /></span></div>
                <div className="col-lg-6">
                  <div className="text-left text-mobile-center">
                    <h2>Diabetesdagbogen til din hverdag med diabetes</h2>
                    <p>Det er vigtigt at holde styr på blodsukkerniveauet for at få indsigt og for at nå frem til den rigtige dosis insulin.</p>
                    <p>Diabetesdagbogen hjælper dig med at genkende mønstre i dit blodsukkerniveau. Dette vil hjælpe dig og din praktiserende læge til at se, hvordan get går, og til at forbedre din behandling.</p>
                    <p>I Diabetesdagbogen kan du registrere blodsukkervædier, oplysninger du ønsker at dele med din læge og få overblik over dit medicinforbrug, samt de aftaler du har laver med din læge eller sygeplejerske.</p>
                  </div>
                </div>
                <div className="col-lg-4">
                <div className="ttm_single_image-wrapper">
                      <img className="img-fluid" src="images/content-images/link3.png" title="Toujeo SoloStar" alt="Toujeo SoloStar" />
                    </div>{/* ttm_single_image-wrapper end */}
                </div>
                <div className='col-lg-2 d-flex align-items-center'>
                                  <div className="ttm-btn-box pr-20 pb-20">
                                    <a className="ttm-btn ttm-btn-size-md rounded-circle ttm-btn-style-border ttm-textcolor-white ttm-bgcolor-background">HENT DIABETEDAGBOGEN</a>
                                </div></div>
              </div>{/* row end */}
              <div className="row m-0 spacing-12 ttm-bgcolor-darkgrey">
                <div className="col-lg-12">
                  <div className="text-center">
                    <p>Du kan bede din læge eller sygeplejerske om en fysisk version af diabetesdagbogen, hvis du vil tage notater.</p>
                  </div>
                </div>
              </div>{/* row end */}
              <div className="row m-0 ttm-bgcolor-white mobile-paging">
              <div className="col-6 text-left">
                                
                                <a onClick={()=> this.setState({ tabIndex: 3 })} className="ttm-btn ttm-icon ttm-icon_element-fill ttm-icon_element-color-background ttm-icon_element-size-lg">
                                    <i className="fa fa-angle-left" />
                                </a>
                              </div>
                              <div className="col-6 text-right">
                              <a onClick={()=> this.setState({ tabIndex: 5 })} className="ttm-btn ttm-icon ttm-icon_element-fill ttm-icon_element-color-background ttm-icon_element-size-lg">
                                    <i className="fa fa-angle-right" />
                                </a>
                              </div>
            </div>{/* row end */}
            </div>
                        </TabPanel>

                        <TabPanel>
                        <div className="content-inner active ttm-shadow">
            <div className="row m-0 spacing-12">
                              <div className='col-lg-12 d-sm-block d-md-none text-mobile-center mb-20'>
                              <span><h1>Rejs trygt</h1><img className="img-fluid img-fluid-restrict" src="images/tab-images/tab6.png" /></span></div>
              <div className="col-lg-6">
                <div className="text-left  text-mobile-center">
                  <h2>Diabetes Rejsehåndbog</h2>
                  <p>Diabetes Rejsehåndbogen giver dig gode råd, når du rejser med insulin. Du kan sagtens rejse og holde ferie, når du er i insulinbehandling, men det er vigtigt at du planlægger din rejse.</p>
                  <p>Diabetes Rejsehåndbogen giver dig tips til planlægning før rejsen og hvad du bør være opmærksom på under rejsen.</p>
                </div>
              </div>
              <div className="col-lg-4">
              <div className="ttm_single_image-wrapper">
                    <img className="img-fluid" src="images/content-images/link4.png" title="Toujeo SoloStar" alt="Toujeo SoloStar" />
                  </div>{/* ttm_single_image-wrapper end */}
              </div>
              <div className='col-lg-2 d-flex align-items-center'>
                <div className="ttm-btn-box pr-20 pb-20">
                  <a className="ttm-btn ttm-btn-size-md rounded-circle ttm-btn-style-border ttm-textcolor-white ttm-bgcolor-background ttm-shadow">HENT RESJESHANDBOG</a>
              </div></div>
            </div>{/* row end */}
            <div className="row m-0 spacing-12 ttm-bgcolor-grey">
              <div className="col-lg-6">
                <div className="text-left  text-mobile-center">
                  <h2>Diabetes Rejsecertifikat</h2>
                  <p>I Diabetes Rejsecertifikatet kan du udfylde vigtige oplysninger, såsom oplysninger om hvilken insulin du bruger, og din nødkontaktperson. 
                    Dette er praktisk at have med på rejser i Danmark og udlandet. Din læge eller sygeplejerske kan hjælpe dig med udstedelsen at et rejsecertifikat.</p>
                </div>
              </div>
              <div className="col-lg-4">
              <div className="ttm_single_image-wrapper">
                    <img className="img-fluid" src="images/content-images/link5.png" title="Toujeo SoloStar" alt="Toujeo SoloStar" />
                  </div>{/* ttm_single_image-wrapper end */}
              </div>
              <div className='col-lg-2 d-flex align-items-center'>
                <div className="ttm-btn-box pr-20 pb-20">
                  <a className="ttm-btn ttm-btn-size-md rounded-circle ttm-btn-style-border ttm-textcolor-white ttm-bgcolor-background ttm-shadow">HENT RESJESCERTIFIKAT</a>
              </div></div>
            </div>{/* row end */}
            <div className="row m-0 spacing-12 ttm-bgcolor-darkgrey">
              <div className="col-lg-12">
                <div className="text-center">
                  <p>Dette materiale er målrettet patienter, som har fået en recept på Toujeo&reg; SoloStar&reg; i Danmark<br/>
                  Materialet og indeholdet er udarbejdet af Sanofi A/S • Vibenhuset • Lyngbyvej 2 • 2100 København Ø • +45 45 16 70 00</p>
                </div>
              </div>
            </div>{/* row end */}
            <div className="row m-0 ttm-bgcolor-white mobile-paging">
              <div className="col-6 text-left">
              <a onClick={()=> this.setState({ tabIndex: 4 })} className="ttm-btn ttm-icon ttm-icon_element-fill ttm-icon_element-color-background ttm-icon_element-size-lg">
                                    <i className="fa fa-angle-left" />
                                </a>
              </div>
              
              <div className="col-6 text-right">
                              <a onClick={()=> this.toggleMainCollapse('collapse1')} className="ttm-btn ttm-icon ttm-icon_element-fill ttm-icon_element-color-background ttm-icon_element-size-lg">
                                    <i className="fa fa-angle-left" />
                                </a>
                              </div>
            </div>{/* row end */}
          </div>
                        </TabPanel>
                        </FadeIn>
                      </div>
                    </Tabs>
                 </div>
               </div>
             </div>
           </div>
         {/*</section>*/}
         {/* tab section end */}

         <CookieConsent className="cookies" buttonClasses='ttm-btn ttm-btn-size-md rounded-circle ttm-btn-style-border' buttonStyle={{ background: "#ffffff" }} buttonText="ACCEPTER OG LUK"><img className="img-fluid" src="images/content-images/cookie.png" title="Toujeo SoloStar" alt="Toujeo SoloStar" /><br/>Denne hjemmeside gør brug af cookies, som gemmer information på din enhed for understøtte hjemmesiden i at virke. Læs mere under <a className='ttm-textcolor-white' href="/">Cookie-politik</a>.<br/>Ved at klikke "Accepter og luk" eller ved at bruge hjemmesiden, accepterer du "Cookie-politik".</CookieConsent>
       </div>

     )
 }
}

export default MainContent;